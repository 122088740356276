import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";

const TARGET_TEXT = 'Crafting Mammoth';
const webExpTargetText = 'Web Experiences';
const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 40;
//const CHARS = "10!@#$%^&*():{};|,.<>/?";
const CHARS = "10";

const EncryptedText = ({setAnimationComplete}) => {
  const intervalRef = useRef(null);
  const [text, setText] = useState(TARGET_TEXT);
  const [subtext, setSubText] = useState(webExpTargetText);
  const [isScrambling, setIsScrambling] = useState(true);

  useEffect(() => {
    scramble();
    //scrambleSub();
    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, []);

  const scramble = () => {
    let pos = 0;

    intervalRef.current = setInterval(() => {
      const scrambled = TARGET_TEXT.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }
          return CHARS[Math.floor(Math.random() * CHARS.length)];
        })
        .join("");

      setText(scrambled);
      pos++;

      if (pos >= TARGET_TEXT.length * CYCLES_PER_LETTER) {
        stopScramble();
        setIsScrambling(false)
        scrambleSub();
      }
    }, SHUFFLE_TIME);
  };

  const scrambleSub = () => {
    let pos = 0;

    intervalRef.current = setInterval(() => {
      const scrambled = webExpTargetText.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }
          return CHARS[Math.floor(Math.random() * CHARS.length)];
        })
        .join("");

      setSubText(scrambled);
      pos++;

      if (pos >= webExpTargetText.length * CYCLES_PER_LETTER) {
        stopScramble();
        setAnimationComplete(true)
      }
    }, SHUFFLE_TIME);
  };

  const stopScramble = () => {
    clearInterval(intervalRef.current);
    setText(TARGET_TEXT);
    setSubText(webExpTargetText);
  };

  return (
    <div className="relative flex justify-center items-center h-screen">
      {/* Animated Gradient Effect */}
      <motion.span
        initial={{ y: "100%" }}
        animate={{ y: "-100%" }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1,
          ease: "linear",
        }}
        className="absolute inset-0 scale-125 bg-gradient-to-t from-yellow-400/10 via-orange-500/80 to-yellow-400/10 opacity-100 z-[-1]"
      />

      {/* Animated Text */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant="h3"
          align='center'
          sx={{
            color: 'white',
            fontSize: {
              xs: '40px',
              sm: '60px',
              md: '97px',
              lg: '97px'
            },
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'bold',
            mb: { xs: 0, md: -2 },
          }}
        >
          {text}
        </Typography>
      </motion.div>

      {!isScrambling && (
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h3"
            align='center'
            sx={{
              color: 'white',
              fontSize: {
                xs: '40px',
                sm: '60px',
                md: '97px',
                lg: '97px'
              },
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 'bold',
              mb: { xs: 0, md: 1, },
            }}
          >
            {subtext}
          </Typography>
        </motion.div>
      )}
    </div>
  );
};

export default EncryptedText;
