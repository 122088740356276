import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Card,
  CardMedia,
  IconButton,
  Box,
  Button,
  AppBar,
  Toolbar,
  Divider,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useLocation } from "react-router-dom";
import { motion, px } from "framer-motion";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

//Custom Imports
import PortfolioCarouselElement from "./Carousel";

const ProjectPage = () => {
  const location = useLocation();
  const project = location.state;
  const pad = "17vw"

  return (

    <Box
      sx={{
        backgroundColor: "#0f0f0f",
        pt: 15,
        px: { xs: "0vw", md: "0vw" },
        minHeight: "100vh",
      }}
    >

      <Grid container sx={{ px: { xs: 0, md: pad } }}>
        <Grid item xs={12} md={10}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0 }}
          >
            <Typography
              sx={{
                textAlign: { xs: "center", md: "left" },
                color: "white",
                fontSize: {
                  xs: "2.5rem",
                  sm: "3.5rem",
                  md: "3rem",
                  lg: "3.25rem",
                },
              }}
            >
              {project.title}
            </Typography>
          </motion.div>
        </Grid>

        {project.url && (
          <Grid item xs={12} md={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "center" },
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0 }}
            >
              <motion.div
                whileHover={{
                  scale: 1.1, // Scale up when hovered
                }}
                transition={{
                  duration: 0.3, // Animation duration
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    //borderRadius: '999px', // Make the button oval
                    //background: "white", // Set the background to transparent
                    color: "white", // Set the text color to white
                    border: "3px solid #0f0f0f", // Set border to transparent initially
                    "&:hover": {
                      backgroundColor: "#F8AD13", // Change background color on hover
                      border: "3px solid #F8AD13", // Change border color on hover
                      color: "#0f0f0f",
                    },
                    width: {
                      xs: "275px",
                      md: "250px",
                      lg: "225px",
                      xl: "200px",
                    },
                    height: { xs: "60px", md: "50px", lg: "40px", xl: "50px" },
                    mt: 2,
                  }}
                  href={project.url}
                  target="_blank"
                >
                  <Typography
                    sx={{
                      fontWeight: 300,
                      //letterSpacing: '.1rem',
                      fontSize: {
                        xs: "1.2rem",
                        md: "1rem",
                        lg: "1rem",
                        xl: "1rem",
                      }, //resize based on screensize
                      fontWeight: "bold",
                    }}
                  >
                    View Project
                  </Typography>
                  <ArrowForwardIcon sx={{ ml: 1 }} />
                </Button>
              </motion.div>
            </motion.div>
          </Grid>
        )}

        <Grid container xs={12} md={5.5}
          sx={{
            pt: 3,
            px: { xs: '15vw', md: 0 },
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  color: "gray",
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                }}
              >
                DESCRIPTION
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "gray", // Customize the color of the divider
                  width: { xs: "100%", md: "75%" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  color: "white",
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.2rem",
                    md: "1.2rem",
                    lg: "1.2rem",
                  },
                  mt: 1,
                }}
              >
                A {project.sub}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  color: "white",
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.2rem",
                    md: "1.2rem",
                    lg: "1.2rem",
                  },
                  mt: 1,
                }}
              >
                {project.desc}
              </Typography>
            </Grid>
          </motion.div>
        </Grid>

        <Grid container xs={12} md={5.5}
          sx={{
            pt: 3,
            pb: { xs: 5, md: 0 },
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <Grid item xs={12} >

              <Typography
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  color: "gray",
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                }}
              >
                DEVELOPMENT
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "gray", // Customize the color of the divider
                  width: { xs: "100%", md: "75%" },
                  mb: 1,
                }}
              />

              {project.chip.map((chip, index) => (
                <Typography
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                    color: "#F8AD13",
                    fontSize: {
                      xs: "1.2rem",
                      sm: "1.2rem",
                      md: "1.2rem",
                      lg: "1.2rem",
                    },
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  {chip}
                </Typography>
              ))}
            </Grid>
          </motion.div>
        </Grid>

      </Grid>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <Grid item xs={12}
          sx={{
            pb: "5vh",
            pt: "3vw",
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          {project.videoUrl && (
            <Box
              sx={{
                width: {
                  xs: "80%",   // For extra-small screens (mobile)
                  sm: "80%",   // For small screens (tablets)
                  md: "70%",   // For medium screens (desktops)
                  lg: "70%",   // For large screens (larger desktops)
                  xl: "60%",   // For extra-large screens (wide monitors)
                },
                margin: "0 auto",  // Center align the video
              }}
            >
              <video
                src={project.videoUrl}
                autoPlay
                muted
                loop
                playsInline
                style={{
                  width: "100%",          // Make the video fill the Box
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                }}
              />
            </Box>
          )}


        </Grid>

        <Grid item xs={12}
          sx={{
            pb: "10vh",
            pt: "3vw",
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          <PortfolioCarouselElement selectedPath={project.title} />
        </Grid>
      </motion.div>
    </Box>

  );
};

export default ProjectPage;
