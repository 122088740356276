import { motion } from "framer-motion";
import { FiCheckCircle } from "react-icons/fi";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import axios from 'axios';
import config from "../../config";
const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
// Set the base URL for Axios requests
const api = axios.create({
  baseURL: config[environment].apiUrl.replace('/api', ''),
});

const TerminalContact = () => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  return (

    <Box
      ref={containerRef}
      onClick={() => inputRef.current?.focus()}
      sx={{
        flexGrow: 1, // Allow it to take all available space
        display: "flex", // Makes it stretch
        flexDirection: "column",
        backgroundColor: "#1e293b",
        backdropFilter: "blur(10px)",
        borderRadius: 2,
        height: '100%',
        minHeight: { xs: '33rem', sm: '33rem', md: '33rem', lg: '33rem', xl: '33rem' },
        width: '100%',
        overflowY: "scroll",
        boxShadow: 3,
        cursor: "text",
        fontFamily: "monospace",
        color: "white",
      }}
    >
      <TerminalHeader />
      <TerminalBody inputRef={inputRef} containerRef={containerRef} />
    </Box>

  );
};

const TerminalHeader = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        //width: "100%",
        p: 2,
        backgroundColor: "#1E1E1E",
        display: "flex",
        alignItems: "center",
        gap: 1,
        position: "sticky",
        top: 0,
      }}
    >
      <Box sx={{ width: 12, height: 12, borderRadius: "50%", backgroundColor: "red" }} />
      <Box sx={{ width: 12, height: 12, borderRadius: "50%", backgroundColor: "yellow" }} />
      <Box sx={{ width: 12, height: 12, borderRadius: "50%", backgroundColor: "green" }} />

      {!isXs && (
        <Typography
          sx={{
            position: "absolute",
            left: { xs: "60%", sm: "50%" },
            transform: "translateX(-50%)",
            fontSize: { xs: 14, sm: 14 },
            fontWeight: "bold",
            color: "grey.300",
          }}
        >
          contact@mammothwebsites.com
        </Typography>
      )}
    </Box>
  );
};

const TerminalBody = ({ containerRef, inputRef }) => {
  const [focused, setFocused] = useState(false);
  const [text, setText] = useState("");
  const [questions, setQuestions] = useState(QUESTIONS);
  const curQuestion = questions.find((q) => !q.complete);

  const handleSubmitLine = (value) => {
    if (curQuestion) {
      setQuestions((prev) =>
        prev.map((q) => (q.key === curQuestion.key ? { ...q, complete: true, value } : q))
      );
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <InitialText />
      <PreviousQuestions questions={questions} />
      <CurrentQuestion curQuestion={curQuestion} />
      {curQuestion ? (
        <CurLine
          text={text}
          focused={focused}
          setText={setText}
          setFocused={setFocused}
          inputRef={inputRef}
          command={curQuestion?.key || ""}
          handleSubmitLine={handleSubmitLine}
          containerRef={containerRef}
        />
      ) : (
        <Summary questions={questions} setQuestions={setQuestions} />
      )}
    </Box>
  );
};

const InitialText = () => (
  <>
    <Typography
      sx={{
        textAlign: { xs: 'center', sm: 'left' },
        color: 'white',
        fontSize: {
          xs: '1rem',
          sm: '1rem',
          md: '1rem',
          lg: '1rem'
        },
        fontFamily: 'Montserrat',
        fontWeight: '600',
      }}
    >
      Let's build together! Use this terminal to send me a message. Type your answers and press Enter. Required = *
    </Typography>
    <Typography
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontWeight: "light",
        textAlign: { xs: 'center', sm: 'left' },
        color: 'white',
        fontSize: {
          xs: '1rem',
          sm: '1rem',
          md: '1rem',
          lg: '1rem'
        },
        fontFamily: 'Montserrat',
        fontWeight: '600',
      }}
    >
      ------------------------------------------------------------------------
    </Typography>
  </>
);

const PreviousQuestions = ({ questions }) => (
  <>
    {questions.map((q, i) =>
      q.complete ? (
        <Fragment key={i}>
          <Typography
            sx={{
              textAlign: { xs: 'left', sm: 'left' },
              color: 'white',
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1rem',
                lg: '1rem'
              },
              fontFamily: 'Montserrat',
              fontWeight: '200',
            }}
          >
            {q.text} <span style={{ color: "#F8AD13" }}>{q.postfix}</span>
          </Typography>
          <Typography
            sx={{
              color: "lightgreen",
              display: "flex",
              alignItems: "center",
              textAlign: { xs: 'left', sm: 'left' },
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1rem',
                lg: '1rem'
              },
              fontFamily: 'Montserrat',
              fontWeight: '200',
            }}
          >
            <FiCheckCircle style={{ marginRight: 8 }} />
            <span>{q.value}</span>
          </Typography>
        </Fragment>
      ) : null
    )}
  </>
);

const CurrentQuestion = ({ curQuestion }) =>
  curQuestion ? (
    <Typography
      sx={{
        textAlign: { xs: 'left', sm: 'left' },
        color: 'white',
        fontSize: {
          xs: '1.1rem',
          sm: '1.1rem',
          md: '1.1rem',
          lg: '1.1rem'
        },
        fontFamily: 'Montserrat',
        fontWeight: '400',
      }}
    >
      {curQuestion.text}
      <span style={{ color: "#F8AD13", }}>
        {curQuestion.postfix}
      </span>
    </Typography>
  ) : null;

const Summary = ({ questions, setQuestions }) => {
  const [complete, setComplete] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const [alertOnSuccess, setAlertOnSuccess] = useState(false);
  const [alertOnError, setAlertOnError] = useState(false);
  const [alertOnCodeError, setAlertOnCodeError] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const alertClose = () => {
    setAlertOnSuccess(false);
    setAlertOnError(false);
    setAlertOnCodeError(false);
  }

  const handleReset = () =>
    setQuestions((prev) => prev.map((q) => ({ ...q, value: "", complete: false })));

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = questions.reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    //console.log(formData);

    // Validate the form fields
    const errors = {};
    for (const key in formData) {
      if (formData[key] === '' && key !== 'website' && key !== 'phone' && key !== 'description') {
        errors[key] = 'This field is required';
      }
    }

    // If there are validation errors, update the state and prevent submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setAlertOnError(true);
      return;
    }

    api.post('/api/sendContact', formData)
      .then(response => {
        //console.log('Response:', response.data);
        setAlertOnSuccess(true);

        // Now send an email after successful form submission
        return api.post('/api/sendGridEmail', formData);
      })
      .catch(error => {
        console.error('Error:', error);
        setAlertOnCodeError(true);
      });

    setComplete(true);
  };


  return (
    <>
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "light",
          textAlign: { xs: 'center', sm: 'left' },
          color: 'white',
          fontSize: {
            xs: '1rem',
            sm: '1rem',
            md: '1rem',
            lg: '1rem'
          },
          fontFamily: 'Montserrat',
          fontWeight: '600',
        }}
      >
        ------------------------------------------------------------------------
      </Typography>
      <Typography
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
          color: 'white',
          fontSize: {
            xs: '1rem',
            sm: '1rem',
            md: '1rem',
            lg: '1.2rem'
          },
          fontFamily: 'Montserrat',
          fontWeight: '400',
        }}
      >
        Nice! Here's what you've entered:
      </Typography>
      {questions.map((q) => (
        <Typography
          key={q.key}
          sx={{
            textAlign: { xs: 'left', sm: 'left' },
            color: 'white',
            fontSize: {
              xs: '1rem',
              sm: '1rem',
              md: '1rem',
              lg: '1.2rem'
            },
            fontFamily: 'Montserrat',
            fontWeight: '400',
          }}
        >
          <span style={{ color: "#90caf9" }}>{q.key}:</span> {q.value}
        </Typography>
      ))}
      <Typography
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
          color: '#F8AD13',
          fontSize: {
            xs: '1rem',
            sm: '1rem',
            md: '1rem',
            lg: '1.2rem'
          },
          fontFamily: 'Montserrat',
          fontWeight: '600',
          mt: 1,
        }}
      >
        Is everything correct?
      </Typography>
      {complete ? (
        <Box>
          <Typography
            sx={{
              color: "lightgreen",
              display: "flex",
              alignItems: "center",
              textAlign: { xs: 'center', sm: 'left' },
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.2rem',
                lg: '1.2rem'
              },
              fontFamily: 'Montserrat',
              fontWeight: '600',
            }}
          >
            {!isXs && (
              <FiCheckCircle style={{ marginRight: 8 }} />
            )}
            Sent! We'll get back to you ASAP 😎
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: "left" },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px', // Make the button oval
                background: '#880808',
                color: 'white', // Set the text color to white
                //border: '3px solid #151515', // Set border to transparent initially
                width: { xs: '32vw', sm: '15vw', md: '10vw', lg: '10vw', xl: '5vw' },
                '&:hover': {
                  backgroundColor: 'black', // Change background color on hover
                  background: 'linear-gradient(to right, #F8AD13, #f57c00)',
                  //border: '3px solid black', // Change border color on hover
                  color: 'white',
                },
                my: 1,
              }}
              onClick={handleReset}
            >
              Restart
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 1,
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: "left" },
          }}
        >
          <motion.div
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.975,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px', // Make the button oval
                background: '#880808',
                color: 'white', // Set the text color to white
                //border: '3px solid #151515', // Set border to transparent initially
                width: { xs: '32vw', sm: '15vw', md: '10vw', xl: '5vw' },
                '&:hover': {
                  backgroundColor: 'black', // Change background color on hover
                  background: 'linear-gradient(to right, #F8AD13, #f57c00)',
                  //border: '3px solid black', // Change border color on hover
                  color: 'white',
                },

              }}
              onClick={handleReset}
            >
              Restart
            </Button>
          </motion.div>
          <motion.div
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.975,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px', // Make the button oval
                background: 'green',
                color: 'white', // Set the text color to white
                //border: '1px solid #1e293b', // Set border to transparent initially
                width: { xs: '32vw', sm: '15vw', md: '10vw', xl: '5vw' },
                '&:hover': {
                  backgroundColor: 'black', // Change background color on hover
                  background: 'linear-gradient(to right, #F8AD13, #f57c00)',
                  //border: '3px solid black', // Change border color on hover
                  color: 'white',
                },

              }}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </motion.div>

          <Snackbar
            open={alertOnSuccess}
            autoHideDuration={4000}
            onClose={alertClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={alertClose}
              sx={{ width: '100%' }}
              severity='success'
              variant='filled'
            >
              <Typography
                //align='center'
                sx={{
                  color: 'white',
                  fontSize: {
                    xs: '2rem',
                    sm: '2rem',
                    md: '1.2rem',
                    lg: '1.2rem'
                  },
                  fontFamily: 'Montserrat',
                  //fontWeight: 'bold',
                }}
              >
                Sent
              </Typography>
            </Alert>
          </Snackbar>

          <Snackbar
            open={alertOnError}
            //autoHideDuration={4000}
            onClose={alertClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={alertClose}
              sx={{ width: '100%' }}
              severity='warning'
              variant='filled'
            //textAlign="center" // Center the text
            >
              <Typography
                //align='center'
                sx={{
                  color: 'white',
                  fontSize: {
                    xs: '2rem',
                    sm: '2rem',
                    md: '1.2rem',
                    lg: '1.2rem'
                  },
                  fontFamily: 'Montserrat',
                  //fontWeight: 'bold',
                }}
              >
                Missing Required Fields
              </Typography>
            </Alert>
          </Snackbar>

          <Snackbar
            open={alertOnCodeError}
            autoHideDuration={4000}
            onClose={alertClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={alertClose}
              sx={{ width: '100%' }}
              severity='error'
              variant='filled'
            >
              <Typography
                //align='center'
                sx={{
                  color: 'white',
                  fontSize: {
                    xs: '2rem',
                    sm: '2rem',
                    md: '1.2rem',
                    lg: '1.2rem'
                  },
                  fontFamily: 'Montserrat',
                  //fontWeight: 'bold',
                }}
              >
                Form Error. Please email contact@mammothwebsites.com
              </Typography>
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

const CurLine = ({ text, focused, setText, setFocused, inputRef, command, handleSubmitLine }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmitLine(text);
    setText("");
  };

  return (
    <Typography
      sx={{
        textAlign: { xs: "left", md: "left" },
        fontSize: "1rem",
        fontFamily: "monospace",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        display: "inline"
      }}
    >
      <span style={{ color: "#34d399" }}>➜</span>{" "}
      <span style={{ color: "#67e8f9", marginRight: "5px" }}>~</span>
      {command &&
        <span style={{ opacity: 0.5, marginRight: "5px" }}>
          Enter {command}:
        </span>
      }

      <form onSubmit={onSubmit} style={{ display: "inline" }}>
        <TextField
          inputRef={inputRef}
          onChange={(e) => setText(e.target.value)}
          value={text}
          variant="standard"
          autoComplete="off"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: "1rem",
              fontFamily: "monospace",
              color: "white",
              backgroundColor: "transparent",
              width: "30vw",
              minWidth: "20px",
              padding: 0,
              margin: 0,
            },
          }}
          sx={{
            display: "inline-block",
            minWidth: "50px",
          }}
        />
      </form>

      {focused && (
        <motion.span
          animate={{ opacity: [1, 1, 0, 0] }}
          transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
          style={{
            display: "inline-block",
            width: 8,
            height: 20,
            backgroundColor: "#94a3b8",
            marginLeft: 4,
          }}
        />
      )}
    </Typography>
  );
};

export default TerminalContact;

const QUESTIONS = [
  { key: "name", text: "To start, could you give us ", postfix: "your name? *", complete: false, value: "" },
  { key: "email", text: "Awesome! And what's ", postfix: "your email? *", complete: false, value: "" },
  { key: "phone", text: "Perfect, and ", postfix: "your phone number?", complete: false, value: "" },
  { key: "website", text: "Do you currently have  ", postfix: "a website?", complete: false, value: "" },
  { key: "description", text: "Finally, ", postfix: "how can we help you?", complete: false, value: "" },
];

